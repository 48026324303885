<template>
	<div key="admin-login" class="admin-login">
		<section id="admin-login">
			<router-link to="/">
				<i class="fas fa-arrow-left"></i>
			</router-link>

			<h2 class="sub-head">Admin login</h2>
			<form @submit.prevent="loginUser">
				<input 
					type="password"
					ref="password"
					placeholder="Enter password" 
					v-model="password"
					@keypress="error = null"
					:disabled="isLoading" required>
				<button type="submit" class="btn btn-primary" value="Submit">
					<div v-if="!isLoading">Submit</div>
					<div v-else class="lds-ring">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</button>
			</form>

			<p v-if="error != null" class="error-message">{{ error }}</p>
		</section>
	</div>
</template>

<script>
import firebase from 'firebase/compat'
require('firebase/auth')

export default {
	name: 'AdminLogin',
	data() {
		return {
			password: '',
			error: null,
			isLoading: false
		}
	},
	methods: {
		async loginUser() {
			try {
				this.isLoading = true;
				await firebase.auth().signInWithEmailAndPassword('rtm909@gmail.com', this.password);
				this.$router.replace('admin');
			} catch (err) {
				this.password = '';
				this.error = err.message.slice(10);
				setTimeout(() => {
					this.focusPassword();
				}, 0)
			}
			this.isLoading = false;
		},
		focusPassword() {
			this.$refs.password.focus();
		},
		async signOut() {
			await firebase.auth().signOut();
		},
	},
	mounted() {
		this.focusPassword();
		this.signOut();
	}
}

</script>

<style scoped>
#admin-login {
	min-height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	background-image: url(../assets/in-page-svgs/admin-login.svg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left;
}

.fa-arrow-left {
	cursor: pointer;
	color: white;
	position: absolute;
	top: 1em;
	left: 1em;
	font-size: 3em;
	transition: all 200ms ease-in-out;
}
.fa-arrow-left:hover {
	transform: scale(1.1);
	color: #f6884d;
}

h1, .designation {
	font-size: 1.7rem;
}
h2 {
	margin-bottom: 10px;
}
@media screen and (max-width: 1060px) {
	h1, .designation {
		font-size: 1.2rem !important;
	}
}

.line{
	height: auto;
	width: 5px;
	background-color: white;
	padding: 0;
	flex: none;
	display: none;
}
@media screen and (max-width: 768px) {
	.line {
		height: 5px;
		margin: 10px;
		width: 100%;
	}
}

.sub-head {
	margin-top: 20px;
	font-size: 1.6rem;
}

input[type=text], input[type=password] {
	border-radius: 25px;
	padding: 8px;
	vertical-align: middle;
	margin: 10px 10px 10px 0;
	border: none;
	color: black;
	background: #f1f1f1;
	opacity: 0.9;
	text-align: left;
}
input[type=text]:focus, input[type=password]:focus {
	background-color: #ddd;
	outline: none;
}

/* Loading spinner */
.lds-ring {
	display: inline-flex;
	position: relative;
	width: 48px;
	transform: scale(0.4);
	bottom: 6px;
	align-items: center;
	justify-content: center;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.error-message {
	color: red;
	max-width: 40%;
	margin: 10px auto 0 auto;
	background-color: black;
	padding: 0.6rem;
	border-radius: 0.5rem;
}
@media screen and (max-width: 768px) {
	.error-message {
		max-width: 80%;
	}
}

</style>