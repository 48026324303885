<template>
	<div class="admin-panel">
		<div class="top-bar">
			<h1 class="sub-head greeting">Welcome back, Rahul Mehta</h1>
			<button class="btn btn-danger" @click="signOut">Sign Out</button>
		</div>
		<div class="navigation-bar" tabindex="2">
			<i class="fas fa-bars menu tab">Menu</i>
			<div class="nav-links">
				<router-link to="/admin/designation" class="tab">Designation</router-link>
				<router-link to="/admin/introduction" class="tab">Introduction</router-link>
				<router-link to="/admin/experience" class="tab">Experience</router-link>
				<router-link to="/admin/skills" class="tab">Skills</router-link>
				<router-link to="/admin/projects" class="tab">Projects</router-link>
				<router-link to="/admin/contact-me" class="tab">Contact Me</router-link>
				<router-link to="/admin/change-password" class="tab">Change Password</router-link>
				<div @click="uploadToFirebase" class="tab upload">Make your changes live!</div>
			</div>
		</div>
		<div ref="notification" class="notification" :class="nClass">
			<i class="fas" :class="icon"></i>
			<span>{{ message }}</span>
		</div>
		<transition
			name="admin-router-view-transition"
			enter-active-class="animated fadeIn"
			leave-active-class="animated fadeOut"
			mode="out-in">
				<router-view
					@notification="gotNotification"
					@deleteProject="updateProjectsToDelete"></router-view>
		</transition>
	</div>
</template>

<script>
import firebase from 'firebase/compat'
require('firebase/auth')

export default {
	name: 'Admin',
	data() {
		return {
			message: '',
			icon: '',
			nClass: '',
			projectImages: []
		}
	},
	methods: {
		async signOut() {
			await firebase.auth().signOut();
			this.$router.replace('/');
		},
		gotNotification(message, type='update') {
			this.message = message;
			if(type == 'error') {
				this.icon = 'fa-exclamation-circle';
			} else if(message.match(/firebase|live/) != null) {
				this.icon = 'fa-cloud-upload-alt';
			} else {
				this.icon = 'fa-check-circle';
			}
			this.nClass = 'enter ' + type;
			setTimeout(() => {
				this.nClass = ''
			}, 5000)
		},
		updateProjectsToDelete(imageName) {
			this.projectimages = this.projectImages.push(imageName);
		},
		uploadToFirebase() {
			if(confirm('This action will push your changes for everyone to see, do you wish to continue?')) {
				if(this.projectImages != []) {
					this.projectImages.forEach(imageName => {
						const deleteRef = firebase.storage().ref('projects/'+ imageName);
						deleteRef.delete();
					});
				}
				const portfolio = {
					designation: this.$store.state.designation,
					about: this.$store.state.about,
					experiences: this.$store.state.experiences,
					skills: this.$store.state.skills,
					skillsExcerpt: this.$store.state.skillsExcerpt,
					projects: this.$store.state.projects,
					contactMeLine: this.$store.state.contactMeLine,
					fileName: this.$store.state.fileName,
				}
				firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
				.then((token) => {
					fetch('https://portfolio-a4ad3-default-rtdb.firebaseio.com/portfolio.json?auth=' + token, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(portfolio)
					}).then(() => {
						this.gotNotification('Your changes are live!', 'firebase');
					})
				}).catch(function(error) {
					console.log(error)
				});
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.admin-panel {
	overflow: hidden;
}

.top-bar {
	padding: 1rem;
	font-size: 0.7rem;
	display: flex;
	justify-content: space-between;
	text-align: left;
}

.greeting {
	margin-right: 1rem;
	margin: 0;
}

.notification {
	position: fixed;
	right: -40px;
	margin-top: 2rem;
	padding: 1rem;
	border-radius: 0.5rem;
	color: white;
	opacity: 0;
	z-index: 10;
	transition: all 300ms ease-in-out;
	
	i {
		margin-right: 0.5rem;
	}
}
.enter {
	right: 30px;
	opacity: 1;
}
.update {
	background-color: darkcyan;
}
.delete, .error {
	background-color: #ab0000; 
}
.firebase {
	background-color: #f8b624;
	color: black;
}

.navigation-bar {
	background-color: #212529;
	transition: all 350ms ease-in-out;
	overflow: hidden;
}
.nav-links {
	display: grid;
	grid-auto-flow: column;
	justify-content: flex-start;
}
.tab {
	color: aliceblue;
	padding: 1rem;
	text-decoration: none;
	transition: all 200ms ease-in-out;
	&:hover {
		background-color: #006666;
	}
}
.upload {
	background-color: #f8b624;
    color: black;
	cursor: pointer;
	text-align: left;
	&:hover {
		background-color: #c08d1d;
	}
}
.router-link-active {
	background-color: darkcyan;
	pointer-events: none;
}
.menu {
	display: none;
	justify-content: flex-start;
	grid-gap: 1rem;
	color: white;
	font-family: 'Tajawal', "Font Awesome 5 Free";
}
@media screen and (max-width: 945px) {
	.menu {
		display: flex !important;
	}
	.navigation-bar {
		height: 48px;
		&:focus {
			height: 216px;
		}
	}
	.nav-links {
		grid-auto-flow: row;
		grid-template-columns: repeat(3, 1fr);
	}
	.tab {
		display: block;
	}
}
@media screen and (max-width: 580px) {
	.nav-links {
		grid-template-columns: repeat(2, 1fr);
	}
	.navigation-bar:focus {
		height: 273px;
	}
}
@media screen and (max-width: 390px) {
	.nav-links {
		grid-template-columns: repeat(1, 1fr);
	}
	.navigation-bar:focus {
		height: 496px;
	}
}
</style>